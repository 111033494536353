<script lang="ts">
  import { onMount, afterUpdate } from "svelte";
  import { SSE } from "sse.js";
  import ChatMessage from "./components/ChatMessage.svelte";
  import PrivacyMessage from "./components/PrivacyMessage.svelte";

  let query = "";
  let answer = "";
  let loading = false;
  let chatMessages = [];
  let scrollToDiv;
  let dataIds = [];
  let matchedDataId = "";
  let intro = "";
  let isdemo = false;
  let displayChatBotWindow = "none";
  let theme = null;
  let modal = false;
  let allowRender = false;
  let isDataset = null;
  let vectorSpace = null;
  let history = [];
  let sessionCount = 0;
  let subscriptionType = "free";
  let sessionFlag = false;
  let userEmail = "";
  let tokenCount = 0;
  let messageQuery="";

  const innerHeight = window.innerHeight;

  const defaultApearinces = {
    general_avatar:
      "https://storage.googleapis.com/1mb-images/5b5729d2efa78e0010ece912/bots-avatar/1671802286156_AVATAR_AYTO_TORREJON_Mesa_de_trabajo_1.jpeg",
    general_name: "Bill",
    header_bgcolor: "#FA615F",
    header_color: "white",
    header_icn_bgcolor: "#f17a7a",
    body_bgcolor: "#fbfbfb",
    body_usr_chat_bgcolor: "#f4f1ff",
    body_usr_chat_color: "black",
    body_bot_chat_bgcolor: "white",
    body_bot_chat_color: "black",
    footer_bgcolor: "#fbfbfb",
    footer_input_bgcolor: "#fbfbfb",
    footer_input_color: "black",
    footer_btn_bgcolor: "#FA615F",
    footer_btn_color: "white",
    header: "#e62f36",
    footer: "#fbfbfb",
    bg: "#cccccc",
    text: "#2e4398",
  };

  const scrollToBottom = () => {
    setTimeout(function () {
      scrollToDiv.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 100);
  }

  afterUpdate(() => {
		 scrollToBottom();
  });
  onMount(async () => {
    const scriptEl = document.querySelector("script[data-id]");
    // dataId = scriptEl?.getAttribute("data-id");
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
      if (document.querySelector("script[data-id]")) {
        console.log("script", scripts[i]?.dataset?.id)
        dataIds.push(scripts[i]?.dataset?.id);
      }
    }

    isdemo = Boolean(scriptEl?.getAttribute("demo"));
    const response = await fetch(
      "https://make-a-bot.vercel.app/api/getBotInfo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ dataIds }),
      }
    );
    const json = await response.json();
    intro = json?.intro;
    theme = json?.theme || defaultApearinces;
    vectorSpace = json?.vectorSpace;
    isDataset = json?.isDataset;
    sessionCount = json?.sessionCount;
    subscriptionType = json?.subscriptionType;
    userEmail = json?.userEmail;
    matchedDataId = json?.matchedDataId;

    if (
      (subscriptionType === "free" && sessionCount >= 10) ||
      (subscriptionType === "basic" && sessionCount >= 1000) ||
      (subscriptionType === "pro" && sessionCount >= 10000)
    ) {
      modal = true;
      sessionFlag = true;
    }

    if (window.location.host === "") {
      allowRender = true;
    } else {
      const getAllowedDomain = await fetch(
        "https://make-a-bot.vercel.app/api/checkAllowedDomain",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            matchedDataId,
            domain: window.location.host.replace(/.+\/\/|www.|\..+/g, ""),
          }),
        }
      );

      const getAllowedDomainJson = await getAllowedDomain.json();
      allowRender = getAllowedDomainJson.responseData;
    }

    if (isdemo) {
      modal = false;
      allowRender = true;
      sessionFlag = false;
    } else {
      modal = true;
    }
  });

  const handleSubmit = async () => {
    loading = true;
    chatMessages = [...chatMessages, { role: "user", content: query }];

    const response = await fetch("https://make-a-bot.vercel.app/api/userInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userEmail }),
    });

    const json = await response.json();
    tokenCount = json?.tokenCount;

    if (
      (tokenCount >= 5000 && subscriptionType == "free") ||
      (tokenCount >= 1000000 && subscriptionType == "basic") ||
      (tokenCount >= 2000000 && subscriptionType == "pro")
    ) {
      answer = "se ha excedido su límite de fichas";
      loading = false;
      query = "";
    } else {
      const eventSource = new SSE(
        "https://make-a-bot.vercel.app/api/getResponse",
        {
          headers: {
            "Content-Type": "application/json",
          },
          payload: JSON.stringify({
            messages: chatMessages,
            matchedDataId,
          }),
        }
      );

      query = "";

      eventSource.addEventListener("error", handleError);


      eventSource.addEventListener("message", (e) => {
        console.log('scrool tobottom')
        scrollToBottom();
        try {
          loading = false;
          if (e.data === "[DONE]") {
            chatMessages = [
              ...chatMessages,
              { role: "assistant", content: answer },
            ];
            answer = "";
            return;
          }

          const completionResponse = JSON.parse(e.data);
          const [{ delta }] = completionResponse.choices;

          if (delta.content) {
            answer = (answer ?? "") + delta.content;
          }
        } catch (err) {
          handleError(err);
        }
      });
      eventSource.stream();
    }

    scrollToBottom();
  };

  function handleError(err) {
    loading = false;
    query = "";
    answer = "ChatGPT/GPT4 saturado ahora; reintente más tarde.";
    console.error(err);
  }
  function handleChat() {
    const chatContainer = document.querySelector(".app__container");
    const display = getComputedStyle(chatContainer).display;
    displayChatBotWindow = display === "none" ? "flex" : "none";
  }

  function handleYesClick() {
    modal = false;
    if (sessionFlag) {
      displayChatBotWindow = "none";
      modal = true;
      window.location.href = "https://make-a-bot.vercel.app/subscription";
    }
  }

  function handleNoClick() {
    displayChatBotWindow = "none";
  }

  const handlePDFchat = async () => {
    loading = true;
    let question = query;
    messageQuery=query;
    console.log('query: ', question, messageQuery);
    chatMessages = [...chatMessages, { role: "user", content: question }];
    query = "";
    await fetch("https://make-a-bot.vercel.app/api/chat", {
      method: "POST",
      body: JSON.stringify({ vectorSpace, question, history, matchedDataId }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        history.push([question, json.text]);
        loading = false;
        chatMessages = [
          ...chatMessages,
          { role: "system", content: json.text },
        ];
      })
      .catch((e) => handleError(e));
  };

</script>

{#if allowRender && theme}
  <meta
    name="viewport"
    content="width=device-width,height='+window.innerHeight+', initial-scale=1"
  />

  <div
    class="app__container"
    style="display:{isdemo
      ? 'flex'
      : displayChatBotWindow}; --inner-height:{innerHeight}"
  >
    <div
      class={"app__chat-container " +
        (modal ? "app__chat-model" : "app__chat-container-h")}
      style="--body-bgcolor:{theme['bg'] || defaultApearinces['bg']};"
    >
      <div
        class="app__chat-window-header"
        style="--header-bgcolor:{theme['header'] ||
          defaultApearinces['header']}"
      >
        <div
          class="app__chat-close-btn"
          style="--header-icn-bgcolor:{theme['header'] ||
            defaultApearinces['header']}"
          on:click={isdemo ? () => null : () => (displayChatBotWindow = "none")}
        >
          <svg
            data-v-0df70446=""
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="50 50 512 512"
            width="20"
            xml:space="preserve"
            focusable="true"
          >
            <path
              data-v-0df70446=""
              fill={defaultApearinces["header_color"]}
              d="M400.601,241H111.399c-8.284,0-15,6.716-15,15s6.716,15,15,15H400.6c8.284,0,15-6.716,15-15
				C415.601,247.716,408.885,241,400.601,241z"
            /></svg
          >
        </div>
        <img
          class="app__chat-avt-head"
          src={theme["general_avatar_url"] ||
            theme["general_avatar"] ||
            defaultApearinces["general_avatar"]}
          alt="avt2"
        />

        <p
          class="app__chat-avt-label"
          style="--header-color:{defaultApearinces['header_color']}"
        >
          {theme["general_name"] || defaultApearinces["general_name"]}
        </p>
      </div>
      {#if modal == true}
        <div class="app__chat-message-box">
          <PrivacyMessage
            {handleYesClick}
            {handleNoClick}
            {modal}
            {sessionFlag}
          />
        </div>
      {/if}
      {#if modal == false}
        <div class="app__chat-message-box">
          <ChatMessage
            type="assistant"
            {theme}
            {defaultApearinces}
            loading={intro ? false : true}
            message={intro}
            messageQuery={messageQuery}
          />
          {#each chatMessages as message}
            <ChatMessage
              type={message.role}
              loading={false}
              {theme}
              {defaultApearinces}
              message={message.content}
              messageQuery={messageQuery}
             
            />
          {/each}
          {#if answer}
            <ChatMessage
              type="assistant"
              loading={false}
              {theme}
              {defaultApearinces}
              message={answer}
               messageQuery={messageQuery}
            />
          {/if}
          {#if loading}
            <ChatMessage
              type="assistant"
              {theme}
              {defaultApearinces}
              {loading}
              message=""
            />
          {/if}
        </div>
        <div class="" bind:this={scrollToDiv} />
      {/if}
    </div>
    {#if modal == false}
      <form
        class="app__chat-send-message"
        style="--footer-bgcolor:{theme['footer'] ||
          defaultApearinces['footer']}"
        on:submit|preventDefault={() => {
          if (matchedDataId && query) {
            console.log('isdataset', isDataset, vectorSpace)
            isDataset && vectorSpace ? handlePDFchat() : handleSubmit();
          } else {
            console.log("data-id is null");
          }
        }}
      >
        <input
          type="text"
          style="--footer-input-bgcolor:{defaultApearinces[
            'footer_input_bgcolor'
          ]}; --footer-input-color:{defaultApearinces['footer_input_color']}"
          bind:value={query}
        />
        <button
          type="submit"
          style="--footer-btn-bgcolor:{theme['header'] ||
            defaultApearinces['header']}; --footer-btn-color:{theme[
            'footer_btn_color'
          ] || defaultApearinces['footer_btn_color']}"
        >
          Send
        </button>
      </form>
    {/if}
  </div>

  <div
    class="app__chat-chatBot"
    style="display: {isdemo
      ? 'none'
      : displayChatBotWindow === 'none'
      ? 'flex'
      : 'none'};"
    on:click={handleChat}
  >
    <img
      src={theme["general_avatar_url"] ||
        theme["general_avatar"] ||
        defaultApearinces["general_avatar"]}
      alt="avt"
    />
  </div>
{/if}

<style lang="scss">
  input,
  button {
    font-family: inherit;
    font-size: inherit;
    -webkit-padding: 0.4em 0;
    padding: 0.4em;
    margin: 0 0 0.5em 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 2px;
  }

  .app__container {
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    flex-direction: column;
    padding: 32px;
    width: 432px;
    padding-left: 48px;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999999;
  }
  @media screen and (max-width: 480px) {
    .app__container {
      width: 100%;
      max-width: 100%;
      padding: 0 !important;
      margin: 0;
      top: 0;
      height: var(--inner-height) !important;
      border-radius: 0 !important;
    }
    .app__chat-container {
      padding: 0px !important;
      border-radius: 0 !important;
    }
    .app__chat-container-h {
      height: calc(100% - 80px) !important;
    }
    .app__chat-window-header {
      margin: 0 !important;
      border-radius: 0 !important;
    }
    .app__chat-send-message input {
      margin-left: 30px !important;
    }
    .app__chat-send-message button {
      margin-right: 30px !important;
    }
    .app__chat-model {
      height: 100% !important;
    }

    .app__chat-message-box {
      padding: 16px;
    }
  }

  .app__chat-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 400px;
    background-color: var(--body-bgcolor);
    border-radius: 6px;
    border-bottom-right-radius: 0%;
    border-bottom-left-radius: 0%;
    padding: 16px;
    gap: 16px;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  }
  .app__chat-container-h {
    height: 500px;
  }
  .app__chat-message-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;
  }
  .app__chat-send-message {
    display: flex;
    align-items: center;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    width: 400px;
    background-color: var(--footer-bgcolor);
    border-radius: 6px;
    border-top-right-radius: 0%;
    border-top-left-radius: 0%;
    padding: 16px;
    gap: 16px;
  }
  .app__chat-send-message input {
    display: flex;
    width: 100%;
    flex-shrink: 1;
    height: 48px;
    font-size: 16px;
    line-height: 24px;
    border-width: 1px;
    background-color: var(--footer-input-bgcolor);
    outline: #2a3344;
    border-radius: 10px;
    color: var(--footer-input-color);
    margin: 0;
    width: 315px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
  }
  .app__chat-send-message button {
    display: inline-flex;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    text-align: center;
    height: 48px;
    width: 70px;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration-line: none;
    color: var(--footer-btn-color);
    background-color: var(--footer-btn-bgcolor);
    border-radius: 10px;
    margin: 0;
  }
  .app__chat-send-message button:active {
    opacity: 0.8;
  }
  .app__chat-send-message button:hover {
    opacity: 0.8;
  }
  .app__chat-chatBot {
    width: fit-content;
    cursor: pointer;
    color: #111827;
    font-weight: bold;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    right: 0;
    border-radius: 100%;
    box-shadow: 0px 0px 2px #111827;
    margin-bottom: 8px;
    margin-right: 11.2px;
  }
  .app__chat-chatBot > img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
  }
  .app__chat-window-header {
    background-color: var(--header-bgcolor);
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin: -16px;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    align-items: center;
  }
  .app__chat-close-btn {
    background-color: var(--header-icn-bgcolor);
    border-radius: 100%;
    max-height: 23px;
    width: 23px;
    cursor: pointer;
    border: 0;
    text-decoration: none;
    text-align: center;
    color: white;
    padding-top: 4px;
    padding-left: 4px;
  }
  .app__chat-close-btn:hover {
    opacity: 0.8;
  }
  .app__chat-avt-head {
    width: 40px;
    border-radius: 100%;
    margin-left: 16px;
  }
  .app__chat-avt-label {
    color: var(--header-color);
    font-weight: 600;
    margin-left: 14px;
    font-size: 20px;
  }
  .app__chat-model {
    height: 582px;
  }
</style>
