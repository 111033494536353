<script lang="ts">
  import { afterUpdate, tick } from 'svelte';
  import SvelteMarkdown from "svelte-markdown";
  export let type;
  export let message;
  export let messageQuery;
  export let theme;
  export let loading;
  export let defaultApearinces;
  
  
  // const updatedMessage = message.match(/^.*[\.\?!]|^\S[^.\?!]*/);
 
 //  console.log('chatMessage',chatMessage);

 const countWords = (str) => {
      const arr = str.split(' '); 
      return arr.filter(word => word !== '').length;
  }
let chatData = [];
const chatMessage = (message,type) => {
  
  if(type === "assistant"){
  const strLength=countWords(message)
  let currentIndex = 0;
  var interval=setInterval(() => {
    const words = message.split(' ')[currentIndex];
    chatData=[...chatData,' ',words].toString().replace(/,/g, "")
    currentIndex++;
   
    if (currentIndex === strLength) {
    clearInterval(interval);
    }
  }, 100);  
  } else{
    
    message= message
  
  }
  
}
message?chatMessage(message,type):'';
</script>

<div
  class="app__chat-chat {type === 'user'
    ? 'app__chat-chat-end'
    : 'app__chat-chat-start'}"
>
  <!-- <div class="chat-image avatar {type!== 'user'? 'bot-avatar':''}">
		<div class="img-container">
			<img
				src="https://ui-avatars.com/api/?name={type === 'user' ? 'Me' : 'B'}"
				alt="{type} avatar"
			/>
		</div>
	</div> -->
  {#if loading == true}
    <div class="app__chat-progress">
      <span />
      <span />
      <span />
    </div>
  {:else}
    <div
      class="app__chat-chat-bubble {type === 'user'
        ? 'app__chat-chat-bubble-primary'
        : 'app__chat-chat-bubble-secondary'}"
      style="--body-bot-chat-bgcolor:{defaultApearinces[
        'body_bot_chat_bgcolor'
      ]}; --body-bot-chat-color:{theme['text'] ||
        defaultApearinces['text']}; --body-usr-chat-bgcolor:{defaultApearinces[
        'body_usr_chat_bgcolor'
      ]}; --body-usr-chat-color:{theme['text'] || defaultApearinces['text']}"
    >
       <SvelteMarkdown source={message===messageQuery?messageQuery:chatData.length>0 ? chatData: message} />
    </div>
  {/if}
</div>

<style>
  .app__chat-progress {
    margin-left: 8px;
    white-space: nowrap !important;
  }
  .app__chat-progress span {
    transition: all 500ms ease;
    background: black;
    box-shadow: 1px 2px 3px #999;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 10px;
    animation: wave 2s ease infinite;
  }

  .app__chat-progress span:nth-child(1) {
    animation-delay: 0;
  }
  .app__chat-progress span:nth-child(2) {
    animation-delay: 100ms;
  }
  .app__chat-progress span:nth-child(3) {
    animation-delay: 200ms;
  }

  @keyframes wave {
    0%,
    40%,
    100% {
      transform: translate(0, 0);
      background-color: black;
    }
    10% {
      transform: translate(0, -15px);
      background-color: black;
    }
  }

  .app__chat-chat {
    display: grid;
    -moz-column-gap: 12px;
    column-gap: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    justify-content: flex-end;
  }

  :global(.app__chat-chat-bubble pre) {
    /* white-space: break-spaces; */
    overflow-x: auto;
  }

  :global(.app__chat-chat-bubble a) {
    word-break: break-word;
  }

  :global(.app__chat-chat-bubble p) {
    font-size: 16px;
    line-height: normal;
    margin: 16px 0 !important;
  }

  .app__chat-chat-start {
    place-items: start;
    grid-template-columns: auto 1fr;
    margin-left: 10px;
  }
  .app__chat-chat-end {
    place-items: end;
    grid-template-columns: 1fr auto;
  }
  .app__chat-chat-bubble {
    position: relative;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    /* padding-top: 0.5rem;
    padding-bottom: 0.5rem; */
    max-width: 90%;
    border-radius: 16px;
    min-height: 28px;
    min-width: 44px;
    grid-column-start: 1;
    color: black;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
  }

  .app__chat-chat-bubble-secondary {
    background-color: var(--body-bot-chat-bgcolor);
    border-bottom-left-radius: 0px;
    color: var(--body-bot-chat-color);
  }
  .app__chat-chat-bubble-primary {
    background-color: var(--body-usr-chat-bgcolor);
    border-bottom-right-radius: 0px;
    color: var(--body-usr-chat-color);
  }
</style>
