<script>
  export let handleYesClick;
  export let handleNoClick;
  export let modal;
  export let sessionFlag;
</script>

<!-- The Modal -->
<div
  id="myModal"
  class={sessionFlag
    ? "app__chat-sessionModal"
    : modal
    ? "app__chat-modal"
    : "app__chat-hide-model"}
>
  <!-- Modal content -->
  <div class="app__chat-modal-content">
    <div style="padding:20px">
      <p style="margin-top:0px">
        {sessionFlag
          ? `Su límite de sesión ha sido excedido`
          : `Guardar las conversaciones me sirve para ir mejorando día a día, añadir
        nuevas funcionalidades y resolver rápidamente tus dudas.`}
      </p>
      {#if sessionFlag == false}
        <p style="font-weight: bold;margin: 22px 0">`¿Estás de acuerdo?`</p>
        <a href="https://1millionbot.com/politica-de-privacidad/"
          ><p class="app__chat-privacy-link">
            `LEER POLÍTICA DE PRIVACIDAD`
          </p></a
        >
      {/if}
    </div>
    <div class="app__chat-btn-container">
      <div on:click={handleYesClick} class="app__chat-btn-yes">
        {sessionFlag ? `Plan` : `Sí`}
      </div>
      <div on:click={handleNoClick} class="app__chat-btn-no">
        {sessionFlag ? `Cancel` : `No`}
      </div>
    </div>
  </div>
</div>

<style>
  /* The Modal (background) */
  .app__chat-modal {
    display: block;
    position: relative;
    border-radius: 15px;
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    margin: 15% auto;
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  @media screen and (max-width: 480px) {
    .app__chat-modal {
      width: 90%;
    }
  }

  .app__chat-sessionModal {
    display: block;
    position: relative;
    border-radius: 15px;
    z-index: 1; /* Sit on top */
    left: 0;
    top: 5em;
    width: 100%; /* Full width */
    margin: 15% auto;
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .app__chat-hide-model {
    display: none;
  }

  .app__chat-privacy-link {
    text-align: center;
    text-decoration: underline;
    font-size: 12px;
    color: #47a3ff;
    margin: 40px 0;
  }

  /* Modal Content/Box */
  .app__chat-modal-content {
    background-color: #fefefe;
    padding-bottom: 0px;
  }

  .app__chat-modal-content p {
    font-size: 16px !important;
    line-height: normal;
  }

  .app__chat-modal-content a {
    width: 100% !important;
  }
  .app__chat-btn-container {
    display: flex;
    border-top: 2px solid #ececec;
    text-align: center;
    cursor: pointer;
    color: #47a3ff;
  }

  .app__chat-btn-yes {
    width: 50%;
    border-right: 0.5px solid #ececec;
    padding: 15px;
  }
  .app__chat-btn-no {
    width: 50%;
    border-left: 0.5px solid #ececec;
    padding: 15px;
  }
</style>
